.form__message {
    display: block;
    margin-block-start: 0;
    padding-block-start: .2em;
    font-size: 1.3rem;

    &.-error {
        color: var(--color-red);
    }

    &:empty {
        display: none;
    }
}

textarea:not([class]) + .form__message {
    margin-block-start: -.7rem;
}
