@use '../mixins/font';
@use '../mixins/responsive';

body {
    @include font.smoothing(); // Disable font-smoothing on places where you don't want it
    @include responsive.size(font-size, 16, 18);

    display: flex;
    flex-direction: column;

    min-width: 320px;
    height: 100%;

    line-height: 1.5;

    background-color: inherit;

    .-is-mobile-menu-open & {
        @media (--show-mobile-menu) {
            height: 100%;
        }
    }

    &.-overflow {
        overflow: hidden;
    }

    > main {
        flex: 1 0 auto;
    }

    > footer {
        flex-shrink: 0;
    }
}
