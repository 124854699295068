:root {
    /* stylelint-disable declaration-colon-space-after  */
    --table-border-color:               var(--text-color-primary);

    --table-head-color:                 var(--text-color-primary);
    --table-head-background-color:      transparent;

    --table-row-color:                  var(--text-color-primary);
    --table-row-background-color-even:  var(--color-blue-white);
    --table-row-background-color-odd:   transparent;
    /* stylelint-enable */
}

table:not([class]), .table {
    table-layout: fixed;
    border-spacing: 0;
    border-collapse: collapse;

    min-width: 30vw;
    max-width: 100%;
    margin-block-end: 3em;

    border: 0;

    tr {
        &:nth-child(even) {
            background-color: var(--table-row-background-color-even);
        }

        &:nth-child(odd) {
            background-color: var(--table-row-background-color-odd);
        }
    }

    th, td {
        padding: 1.2em 1em;

        color: var(--table-row-color);
        text-align: start;
        vertical-align: top;

        border: 1px solid var(--table-border-color);

        @media (--screen-mini) {
            padding: 1em .6em;
            font-size: 1.4rem;
        }
    }

    th {
        color: var(--table-head-color);
        text-align: start;
        background: var(--table-head-background-color);
    }

    @media (--screen-medium) {
        min-width: 50vw;
    }

    @media (--screen-medium-small) {
        width: 100%;
        min-width: 0;
    }
}
