blockquote:not([class]) {
    font-size: 110%;
    font-style: italic;

    &::before, &::after {
        content: '"';
        font-weight: var(--font-primary-weight-bold);
        color: var(--blockquote-quote-color, var(--color-secondary));
    }
}
