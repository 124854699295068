@font-face {
    font-family: 'Open Sans';
    font-weight: 400;
    font-style: normal;
    src: url('../../fonts/open-sans/open-sans-v34-latin-regular.woff2') format('woff2');
}

@font-face {
    font-family: 'Open Sans';
    font-weight: 500;
    font-style: normal;
    src: url('../../fonts/open-sans/open-sans-v34-latin-500.woff2') format('woff2');
}

@font-face {
    font-family: 'Open Sans';
    font-weight: 700;
    font-style: normal;
    src: url('../../fonts/open-sans/open-sans-v34-latin-700.woff2') format('woff2');
}

@font-face {
    font-family: 'Signika';
    font-weight: 700;
    font-style: normal;
    src: url('../../fonts/signika/signika-v20-latin-ext_latin-700.woff2') format('woff2');
}
