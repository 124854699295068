@use '../mixins/responsive';

// Defaults & resets
%input-base-reset {
    @include responsive.size(font-size, 15, 17);

    width: 100%;
    margin: 0;
    padding: 1rem;

    font-family: var(--font-primary);
    line-height: normal;
}

%input {
    @extend %input-base-reset;

    display: inline-block;

    box-sizing: border-box;
    padding: .7em;

    color: var(--color-text);
    word-break: normal;

    background-color: var(--color-white);
    border: 1px solid var(--input-border-color, var(--color-lines));
    border-radius: var(--form-input-border-radius, 0);

    transition: background-color var(--transition-default), border-color var(--transition-default);

    &::placeholder {
        opacity: .8;
    }

    &:focus-visible {
        border-color: transparent;
        outline: 2px solid var(--input-focus-outline-color, var(--color-lines));
    }

    &:disabled {
        opacity: .6;

        + label {
            opacity: .8;
        }
    }
}

input:not([class]), .input {
    @extend %input;

    &[type='checkbox'], &[type='radio'] {
        display: inline-block;

        width: auto;
        height: 1.5em;
        margin-inline-start: .3rem;

        opacity: .65;

        &:focus {
            outline: auto;
        }
    }

    &.-auto-width {
        width: auto;
    }
}

input.special-password { // Hide honeypot fields
    display: none;
}
