/* stylelint-disable declaration-colon-space-after, max-empty-lines */

@custom-media --show-mobile-menu (max-width: 1119px);
@custom-media --show-desktop-menu (min-width: 1120px);
@custom-media --inner-container-start-vw-padding (max-width: 720px);

:root {
    --outer-container-max-width:                auto;
    --inner-container-padding-inline:           min(4vw, 25px);
    --inner-container-medium-max-width:         120rem;
    --article-container-max-width:              43em;
    --form-container-max-width:                 calc(68rem + 2 * var(--inner-container-padding-inline));
    --inner-container-max-width:                160rem;
    --layout-default-block-margin:              4em;
}
