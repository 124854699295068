@custom-media --background-large    (width < 1600px);

.background-scroll > img {
    will-change: transform;

    position: absolute;
    z-index: -1;
    transform: translateY(var(--ty)) rotate(var(--r));

    opacity: .7;

    transition: transform .1s linear;

    &::before {
        content: attr(data-timing);
    }

    &:first-of-type {
        inset-block-start: 30rem;
        inset-inline-start: 5rem;
        width: 4rem;
    }

    &:nth-of-type(2) {
        inset-block-start: 43rem;
        inset-inline-start: 18rem;
        width: 4rem;
    }

    &:nth-of-type(3) {
        inset-block-start: 68rem;
        inset-inline-start: 11rem;
        width: 4rem;
    }

    &:nth-of-type(4) {
        inset-block-start: 62rem;
        inset-inline-end: 22rem;
        width: 4rem;
    }

    &:nth-of-type(5) {
        inset-block-start: 40rem;
        inset-inline-end: 2rem;
        width: 4rem;
    }

    @media (--background-large) {
        opacity: .17;
    }
}
