@use '../node_modules/sanitize.css/sanitize.css';
@use '../node_modules/sanitize.css/reduce-motion.css';
@use '../node_modules/sanitize.css/forms.css';

*,
::before,
::after {
    mask-repeat: no-repeat;
}

:where(*) {
    margin: 0;
}

/**
 * 1. Don't behave as inline element by default which creates weird situations with margins
 */
:where(img) {
    display: inline-block; /* 1 */
    max-width: 100%;
    vertical-align: top;
}

/**
 * https://www.zachleat.com/web/fluid-images/
 */
:where(img[width][height]) {
    height: auto;
}

:where(dl, ol, ul) {
    padding: 0;
    list-style: none;
}

/**
 * Restrict sizing to the page width in all browsers (opinionated).
 */

:where(iframe, img, input, video, select, textarea) {
    max-width: 100%;
}

/**
 * Inherit parent color, more convenient than default browser blue
 */
:where(a) {
    color: inherit;
}

:where(figure) {
    margin-inline: 0;
}

/**
 * HARD reset to remove shadow in input for IOS
 */
:where(input:not([type='checkbox'], [type='radio'])) {
    // stylelint-disable-next-line
    -webkit-appearance: none !important;
}

/**
 * https://github.com/mike-engel/a11y-css-reset
 */
*:focus:not(:focus-visible),
*::before:focus:not(:focus-visible),
*::after:focus:not(:focus-visible) {
    outline: none;
}
