@use '../mixins/responsive';

%title {
    position: relative;

    display: block;

    margin-block: var(--title-margin-block, 1.25em .5em);

    font-family: var(--font-secondary);
    font-weight: var(--font-secondary-weight-bold);
    line-height: var(--title-line-height, 1.25);
    color: var(--title-color, var(--color-primary));
    text-shadow: var(--title-text-shadow, none);

    a {
        color: inherit;
        text-decoration: none;

        &:--focus {
            color: var(--title-hover-color, var(--color-secondary));
        }
    }

    &.-centered {
        text-align: center;
    }
}

.title--1, h1:not([class]) {
    @extend %title;

    @include responsive.size(font-size, 38, 60);

    margin-block-start: 0;
    font-family: var(--title-1-font-family, var(--font-secondary));
    line-height: 1.15;
    text-transform: var(--title-1-text-transform, none);
}

.title--2, h2:not([class]) {
    @extend %title;

    @include responsive.size(font-size, 30, 43);

    /* .subtitle--1 + {
        margin-top: 0;
    } */

    &.-extra-margin {
        margin-block-end: 1.5em;
    }
}

.title--3, h3:not([class]) {
    @extend %title;

    @include responsive.size(font-size, 22, 28);
}

.title--4, h4:not([class]) {
    @extend %title;

    @include responsive.size(font-size, 20, 24);
}

.title--5, h5:not([class]) {
    @extend %title;

    @include responsive.size(font-size, 19, 21);
}

.title--6, h6:not([class]) {
    @extend %title;

    @include responsive.size(font-size, 18, 20);
}

.subtitle--1 {
    @extend %title;

    @include responsive.size(font-size, 28, 36);

    inset-block-start: -18em;
    font-weight: normal;
    color: var(--color-blue-light);
    text-align: center;
}

.title--footer {
    @extend .title--4;

    margin-block: 1rem 1.5rem;
    color: var(--title-footer-color, inherit);
    text-shadow: none;
}

.title-card {
    margin-block: 0;
    font-size: 3.2rem;
    line-height: 1.1;
    color: var(--color-white);

    &.-small {
        margin-block-start: 0;
        font-size: 2.7rem;

        @media (--screen-mini) {
            font-size: 2rem;
        }
    }

    @media (--screen-large) {
        font-size: 2.8rem;
    }

    @media (--screen-mini) {
        font-size: 2rem;
    }
}
