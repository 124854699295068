details.shop-accordion {
    > summary {
        cursor: pointer;

        display: flex;
        gap: 2em;
        align-items: center;
        justify-content: space-between;

        padding: .3em 1em;

        color: var(--color-white);

        background-color: var(--color-brown-light);
        border: 1px solid;

        &::-webkit-details-marker {
            display: none;
        }

        &::marker {
            display: none;
            color: transparent; /* solution for hiding in firefox */
        }

        &::after {
            content: '+';

            display: inline-block;

            margin-inline-start: .8rem;

            font-size: 3rem;
            font-weight: bold;
            color: inherit;

            transition: rotate .3s ease;
        }

        h4 {
            display: inline-block;

            margin-block: 0;
            margin-block-start: 0;

            color: inherit;
            text-shadow: none;
        }

        &:hover {
            background-color: var(--color-brown);
        }
    }

    &[open] {
        > summary {
            &::after {
                content: '-';
                margin-inline-end: .4rem;
            }
        }
    }
}
