.address {
    font-style: normal;

    &__item {
        cursor: pointer;

        display: inline-flex;

        margin-block-end: 3rem;

        text-decoration: none;

        transition: color .2s ease-in-out;

        &:--focus {
            svg {
                fill: var(--address-svg-hover-color, currentcolor);
            }
        }

        svg {
            position: relative;
            inset-block-start: .2rem;

            display: inline-block;

            margin-inline-end: 1.5rem;

            fill: var(--address-svg-color, var(--color-primary));

            transition: fill .2s ease-in-out;

            @media (--screen-small) {
                position: static;
            }
        }
    }

    @media (--screen-medium) {
        margin-block-start: 5rem;
    }
}
