@import '~tiny-slider/dist/tiny-slider.css';

.slider {

    &-wrapper {
        position: relative;
        display: none;
        margin-block-end: 3rem;

        @media (--screen-medium) {
            display: block;
            border-block-end: 1px solid var(--color-gray-dark);
        }

        @media (--screen-mini-small) {
            margin-block-end: 1rem;
        }
    }

    &__image {
        width: 100%;
        height: auto;
    }

    &__controls {
        font-size: 3rem;

        &_left, &_right {
            cursor: pointer;

            position: absolute;
            inset-block-start: 50%;
            transform: translateY(-50%);

            display: flex;
            flex-direction: column;
            justify-content: center;

            width: 5rem;
            height: 5rem;

            color: var(--color-white);

            background-color: var(--color-primary);
            border: none;

            transition: .3s ease-in-out background-color;

            &::after {
                content: '';

                position: absolute;
                inset: 0;

                display: block;

                background-color: var(--color-primary-contrast-text);

                mask-position: center;
            }

            > img {
                display: none;
            }

            &:--focus {
                background-color: var(--color-primary);
                outline: none;
            }

            @media (--screen-small) {
                width: 4rem;
                height: 4rem;
            }
        }

        &_left {
            inset-inline-start: 0;

            &::after {
                mask-image: url('../images/angle-left.svg');
            }
        }

        &_right {
            inset-inline-end: 0;

            &::after {
                mask-image: url('../images/angle-right.svg');
            }
        }
    }
}

.tns-outer > button {
    display: none;
}

.tns-outer > button {
    display: none;
}
