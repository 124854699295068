.footer-navigation {
    column-count: 2;
    column-gap: 12vw;

    margin-block: 1rem 0;
    padding: 0;

    font-size: 1.7rem;
    list-style-type: none;

    &__item {
        display: block;
        margin-block-end: 1.6rem;
        font-size: 1.8rem;
    }

    &__link {
        text-decoration: none;
        transition: opacity .2s ease-in-out;

        &:--focus {
            opacity: .8;
        }
    }

    @media (--footer-medium) {
        column-count: 1;
        margin-block-start: 4rem;
        text-align: center;
    }

    @media (--screen-mini) {
        font-size: 1.6rem;
    }
}
