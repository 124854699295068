@use 'config.scss';
@use 'fonts.scss';

.address__item {
    &:nth-chil(1) {
        svg {
            fill: var(--color-blue);
        }
    }
    &:nth-child(3) {
        svg {
            fill: var(--color-yellow);
        }
    }
    &:nth-child(5) {
        svg {
            fill: var(--color-red);
        }
    }
    &:nth-child(7) {
        svg {
            fill: var(--color-green);
        }
    }
}
