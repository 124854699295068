%anchor {
    color: var(--anchor-color, var(--color-primary));
    text-decoration-line: underline;
    text-decoration-thickness: 2px;
    transition: .2s color ease-in-out;

    &:--focus {
        color: var(--color-primary-light);
    }
}

:where(a:not([class])) {
    @extend %anchor;
}

:where(a[href^='tel']:not([class])) {
    cursor: inherit;
    color: inherit;
    text-decoration: inherit;

    &:--focus {
        cursor: inherit;
        color: inherit;
        text-decoration: inherit;
    }
}
