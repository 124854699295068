$dp-font-size-normal: 1.6rem;
$dp-font-size-small: 1.2rem;
$dp-cell-size-base: 3.6rem;
$link: hsl(51, 95%, 54%);

@import '../../node_modules/vanillajs-datepicker/sass/datepicker';

.datepicker-title {
    padding: .6rem 1.2rem;
}

.prev-btn,
.next-btn {
    padding-inline: .6rem;
}
