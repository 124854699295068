@use '../mixins/container';

@custom-media --l-header-rounded (min-width: 1460px);

:root {
    --l-header-min-height: 21rem;
}

.l-header {
    color: var(--header-navigation-color, var(--color-primary));
    background-color: var(--header-background-color, transparent);

    a {
        color: inherit;

        &:--focus {
            color: inherit;
        }
    }

    &__inner {
        @include container.inner();

        display: flex;
        gap: 3rem;
        align-items: stretch;
        justify-content: space-between;

        padding-block: 1.7rem 2.3rem;

        box-shadow: var(--shadow-header);

        @media (--show-desktop-menu) {
            z-index: 1;
            min-height: var(--l-header-min-height);
        }

        @media (--show-mobile-menu) {
            //--l-header-min-height: 6.8rem;
            position: static;
            padding-block: 1.2rem 1rem;
        }
    }

    &__logo {
        flex: 1;

        @media (--show-desktop-menu) {
            display: flex;
            align-items: center;
        }

        @media (--show-mobile-menu) {
            img {
                max-height: 12rem;
                object-fit: contain;
                object-position: left;
            }
        }
    }

    &__navigation {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;

        @media (--show-mobile-menu) {
            display: none;

            .-is-mobile-menu-open & {
                position: absolute;
                z-index: var(--z-index-mobile-header);
                inset: 0;

                overflow: auto;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;

                padding-block-start: 14rem;

                color: var(--header-mobile-color, var(--color-primary-contrast-text));

                background: var(--header-mobile-background-color, var(--color-primary));

                a:not([class]) {
                    color: inherit;
                }
            }
        }
    }

    nav[aria-label='secondary'] {
        display: flex;
        gap: 2rem;
        align-items: flex-start;
        justify-content: flex-end;

        @media (--show-mobile-menu) {
            flex-direction: column;
            align-items: center;
        }
    }

    .-is-mobile-menu-open & {
        color: var(--header-mobile-color, var(--color-primary-contrast-text));
    }
}
