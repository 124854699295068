@keyframes bounce-odd {
    from,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    }

    0% {
        transform: translate3d(0, -1000px, 0) rotate(6deg);
        opacity: 0;
    }

    60% {
        transform: translate3d(0, 25px, 0) rotate(4deg);
        opacity: 1;
    }

    75% {
        transform: translate3d(0, -10px, 0) rotate(2deg);
    }

    90% {
        transform: translate3d(0, 5px, 0) rotate(2deg);
    }

    to {
        transform: translate3d(0, 0, 0) rotate(3deg);
    }
}
