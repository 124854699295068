.top-navigation {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem 3rem;
    align-items: center;
    justify-content: center;

    font-weight: var(--font-primary-weight-bold);

    a {
        display: inline-block;
        text-decoration: none;
        color: var(--top-navigation-color, inherit);

        &:--focus {
            text-decoration: underline;
        }
    }

    @media (--show-mobile-menu) {
        flex-direction: column;
    }
}
