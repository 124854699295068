.h-padding-vertical {
    padding-block: 3rem 7rem;

    @media (--screen-mini-small) {
        padding-block-end: 5rem;
    }
}

.h-margin-bottom-0 {
    margin-block-end: 0;
}

.h-margin-bottom-1 {
    margin-block-end: 1em;
}

.h-gray {
    font-size: 1.5rem;
    color: var(--color-gray-dark);

    &.-large {
        font-size: inherit;
    }
}

.h-lowercase {
    text-transform: lowercase;
}

.h-centered {
    display: block;
    text-align: center;
}

.h-hidden {
    display: none;
}
