.top-message {
    margin: 0;
    font-weight: bold;
    text-align: center;

    &.-demo {
        color: white;
        background-color: red;
    }
}
