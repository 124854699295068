/* stylelint-disable declaration-colon-space-after, max-empty-lines */
:root {
    --color-white:                      hsl(0, 0%, 100%);
    --color-gray-light:                 hsl(0, 0%, 86% / .45);
    --color-gray:                       hsl(40, 12%, 80%);
    --color-gray-dark:                  hsl(30, 3%, 44%);
    --color-black:                      hsl(0, 0%, 0%);

    --color-red:                        red;

    --color-primary:                    hsl(222, 56%, 30%);
    --color-primary-light:              hsl(200, 68%, 52%);
    --color-primary-contrast-text:      var(--color-white);

    --color-secondary:                  hsl(200, 68%, 52%);
    --color-secondary-light:            hsl(200, 68%, 62%);
    --color-secondary-contrast-text:    var(--color-black);

    --color-text:                       hsl(0, 0%, 2%);
    --color-accent:                     var(--color-primary);
    --color-lines:                      var(--color-gray);
}
