.jumbotron {
    margin-block: 1rem 1vw;

    > img {
        width: 100%;
        height: auto;
        min-height: 32rem;
        max-height: 60rem;

        object-fit: cover;
    }

    @media (--screen-huge) {
        margin-block-end: 7rem;
    }

    @media (--screen-medium) {
        margin-block-end: 0;
    }
}
