/* stylelint-disable declaration-colon-space-after, max-empty-lines */
:root {
    --color-blue-white:                 hsl(200, 31%, 94%);
    --color-blue:                       hsl(215, 76%, 40%);
    --color-blue-light:                 hsl(182, 70%, 60%);
    --color-green:                      hsl(141, 76%, 41%);
    --color-yellow:                     hsl(47, 100%, 57%);
    --color-red:                        hsl(356, 97%, 54%);

    --color-primary:                    var(--color-blue);
    --color-primary-contrast-text:      var(--color-white);

    --color-secondary:                  var(--color-blue-light);
    --color-secondary-contrast-text:    var(--color-black);


    --font-primary:                     'Open sans', sans-serif;
    --font-secondary:                   'Signika', sans-serif;

    --font-primary-weight-regular:      400;
    --font-primary-weight-medium:       500;
    --font-primary-weight-bold:         700;

    --font-secondary-weight-medium:     500;
    --font-secondary-weight-bold:       800;

    --color-accent: var(--color-blue-light);

    --header-background-color: var(--color-blue-light);
    --footer-color: var(--color-blue);
    --footer-background-color: var(--color-blue-light);
    --header-mobile-background-color: var(--color-blue-light);
    --header-mobile-color: var(--color-blue);

    --main-navigation-color-1: var(--color-blue);
    --main-navigation-color-1-hover: var(--color-blue-light);

    --main-navigation-color-2: var(--color-yellow);
    --main-navigation-color-2-hover: var(--color-blue-light);

    --main-navigation-color-3: var(--color-red);
    --main-navigation-color-3-hover: var(--color-blue-light);

    --main-navigation-color-4: var(--color-green);
    --main-navigation-color-4-hover: var(--color-blue-light);

    --main-navigation-color-5: var(--color-blue);
    --main-navigation-color-5-hover: var(--color-blue-light);

    --main-navigation-color-6: var(--color-yellow);
    --main-navigation-color-6-hover: var(--color-blue-light);

    --cookieconsent-button-background-color: var(--color-green);
}
