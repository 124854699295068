@use '../mixins/container';

.l-container--inner {
    @include container.inner();

    &.-medium {
        max-width: var(--inner-container-medium-max-width);
    }
}

.l-container--outer {
    @include container.outer();
}
