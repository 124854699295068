@use 'form/date';
@use 'form/entry';
@use 'form/message';
@use 'form/row';
@use 'form/options';
@use 'form/button-input';

:root {
    --form-row-gap: 2rem;
    --form-column-gap: 3rem;
}
