.card {
    position: relative;
    z-index: 0;

    overflow: hidden;
    display: block;

    max-height: 37rem;

    text-decoration: none;

    background-color: white;

    &::after {
        content: '';

        position: absolute;
        inset-block-end: 0;
        inset-inline: 0;

        display: block;

        height: 80%;

        background: linear-gradient(180deg, transparent 30%, rgba(0, 0, 0, .55));
    }

    &__content {
        position: absolute;
        z-index: 1;
        inset-block-end: 2rem;
        inset-inline: 2.2rem;

        line-height: 1.2;
        color: var(--card-color, var(--color-white));
        text-shadow: var(--card-text-shadow, none);

        &.-small {
            inset-block-end: 1rem;
            inset-inline: 1.6rem;
        }

        @media (--screen-medium) {
            inset-block-end: 1.5rem;
            inset-inline-start: 1.7rem;
        }

        @media (--screen-mini-small) {
            inset-block-end: 1.3rem;
            inset-inline-start: 1.5rem;
        }
    }

    &__image {
        width: 100%;
        height: auto;
        max-height: 35rem;

        opacity: .9;
        object-fit: cover;

        transition: transform .2s ease-in-out, opacity .2s ease-in-out;

        mask-image: linear-gradient(to top, transparent 0%, black 20%);
    }

    &__discount {
        text-decoration: line-through;
    }

    &__price {
        display: inline-block;

        margin-block-start: .5rem;

        font-size: 1.8rem;
        font-weight: var(--font-primary-weight-medium);
        color: var(--card-color, var(--color-white));
    }

    &:--focus img {
        transform: scale(1.05);
    }
}
