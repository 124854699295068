@mixin container() {
    position: relative;
    width: 100%;
    margin-inline: auto;
}

%container {
    @include container();
}

/* Max site width without padding */
@mixin outer() {
    @extend %container;

    max-width: var(--outer-container-max-width);
}

/* Max content width with side padding */
@mixin inner() {
    @extend %container;

    max-width: calc(var(--inner-container-max-width) + 2 * var(--inner-container-padding-inline));
    padding-inline: var(--inner-container-padding-inline);
}

/* Max content width without */
@mixin inner-no-padding() {
    @extend %container;

    max-width: var(--inner-container-max-width);

    @media (max-width: 1429px) {
        max-width: none;
    }
}

/**
 * Use padding instead of margin so it is easier to select in pagebuilder
 */
@mixin article() {
    @extend %container;

    padding-inline: max(var(--inner-container-padding-inline), calc((100% - var(--article-container-max-width)) / 2));
}
