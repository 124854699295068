@use '../mixins/responsive';

@custom-media --navigation-large     (width < 1460px);
@custom-media --navigation-medium     (width < 1150px);

.main-navigation {
    @include responsive.size(column-gap, 15, 25, 1280, 1440);

    display: flex;
    flex-wrap: wrap;
    row-gap: 2.5rem;
    align-items: center;
    justify-content: center;

    margin-block-end: 5rem;
    padding-inline-start: 0;

    list-style-type: none;

    &__item {
        display: inline-block;
        color: var(--main-navigation-color, var(--color-primary-contrast-text));
        box-shadow: var(--main-navigation-box-shadow, none);

        &:nth-of-type(odd) {
            transform: rotate(var(--main-navigation-rotate-odd, 3deg));
        }

        &:nth-of-type(even) {
            transform: rotate(var(--main-navigation-rotate-even, -3deg));
        }

        @media (--header-medium) {
            margin-block-end: 3rem;
        }

        @for $i from 1 through 6 {
            &:nth-of-type(#{$i}) > a {
                background-color: var(--main-navigation-color-#{$i}, var(--color-primary));

                &:--focus {
                    background-color: var(--main-navigation-color-#{$i}-hover, var(--color-primary-light));
                    color: var(--main-navigation-color-focus, currentcolor);
                }
            }
        }
    }

    &__link {
        @include responsive.size(font-size, 18, 21, 1200, 1400);

        display: inline-block;

        padding: var(--main-navigation-padding, .6em 1em .5em);

        font-family: var(--main-navigation-font-family, var(--font-secondary));
        font-weight: var(--main-navigation-font-weight, var(--font-secondary-weight-medium));
        color: var(--color-white);
        text-decoration: none;
        text-transform: var(--main-navigation-text-transform, uppercase);
        letter-spacing: var(--main-navigation-letter-spacing, 0);

        border: var(--main-navigation-border, 0);
        border-radius: var(--main-navigation-border-radius, 0);

        transition: background-color .2s ease-in-out;

        .-overlay & {
            padding-inline: 1.1em;

            @media (--screen-mini) {
                padding-inline: .7em;
            }
        }

        @media (--navigation-medium) {
            padding-inline: .7em;
        }
    }

    @media (--show-mobile-menu) {
        flex-direction: column;
    }

    @media (--header-medium) {
        display: none;

        .-overlay & {
            display: flex;
            flex-direction: column;
            margin-block-end: 2rem;
        }
    }
}

.bounce > li {
    animation-duration: .9s;

    &:nth-of-type(2) {
        animation-delay: .02s;
    }

    &:nth-of-type(3) {
        animation-delay: .06s;
    }

    &:nth-of-type(4) {
        animation-delay: .1s;
    }

    &:nth-of-type(5) {
        animation-delay: .14s;
    }

    &:nth-of-type(6) {
        animation-delay: .18s;
    }

    &:nth-of-type(even) {
        animation-name: bounce-even;
    }

    &:nth-of-type(odd) {
        animation-name: bounce-odd;
    }
}
