.toc {
    margin-block-start: 5rem;
    font-size: 1.9rem;
    border-block-end: 1px solid var(--color-gray-dark);

    > ul {
        display: block;

        > li {
            display: inline-block;
            margin-inline-end: 1.5em;

            &:last-child {
                margin-inline-end: 0;
            }

            @media (--screen-mini) {
                margin-inline-end: 4vw;
            }
        }
    }

    a:not([class]) {
        font-family: var(--font-secondary);
        font-weight: bold;
        color: var(--color-secondary);
        text-decoration: none;

        &:--focus {
            text-decoration: underline;
        }
    }
}
