%form__row {
    display: grid;
    gap: 0 var(--form-column-gap);
    align-items: baseline; // Needed to align labels (when going over multiple lines) and error messages
}

.form__row {
    @extend %form__row;

    grid-template-columns: repeat(auto-fit, minmax(14em, 1fr));
}
