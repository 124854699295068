/* stylelint-disable declaration-colon-space-after, max-empty-lines */
:root {
    --font-primary:                     verdana, sans-serif;
    --font-secondary:                   verdana, sans-serif;

    --font-primary-weight-regular:      400;
    --font-primary-weight-medium:       500;
    --font-primary-weight-bold:         700;

    --font-secondary-weight-medium:     500;
    --font-secondary-weight-bold:       800;
}
