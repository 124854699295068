:root {
    --hamburger-layer-color: currentcolor;
}

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
$hamburger-padding-x: 0;
$hamburger-padding-y: 0;
$hamburger-layer-width: 19px;
$hamburger-layer-height: 3px;
$hamburger-layer-spacing: 4px;
$hamburger-layer-color: var(--hamburger-layer-color);
$hamburger-layer-border-radius: 3px;
$hamburger-hover-opacity: 1;
$hamburger-active-layer-color: var(--hamburger-layer-color);
$hamburger-active-hover-opacity: 1;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: false;
$hamburger-hover-filter: opacity(50%);
$hamburger-active-hover-filter: opacity(50%);

$hamburger-types: (
    collapse,
);

@import '../node_modules/hamburgers/_sass/hamburgers/_base.scss';
@import '../node_modules/hamburgers/_sass/hamburgers/types/_collapse.scss';

.hamburger {
    display: block;
    outline: none;
    transition: transform .3s ease-in-out;
}
