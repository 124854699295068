@use '../mixins/container';

.l-article {
    @include container.article();

    margin-block-end: var(--layout-default-block-margin);
}

/* .l-article--wider {
    @include container.article-wider();
    margin-block: var(--layout-default-block-margin);
} */
