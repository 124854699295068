.newsletter {
    max-width: 45rem;
    margin: 0 auto;

    &.-subscribed {
        max-width: none;
        margin: 10rem auto;
        text-align: center;
    }

    &-wrapper {
        padding-block: 2rem 4rem;
        background-color: var(--color-gray-light);
    }

    &__title {
        margin-block-end: 1rem;

        font-family: var(--font-secondary);
        font-size: 4.5rem;
        line-height: 1.25;
        color: var(--color-primary);

        @media (--screen-mini-small) {
            font-size: 3.3rem;
        }
    }

    &__description {
        font-size: 1.8rem;
        font-weight: var(--font-primary-weight-bold);
    }
}

.newsletter-form {
    margin-block-start: 4rem;

    input {
        margin-block-end: 1.5rem !important;
        border-color: transparent !important;

        &:focus, &:active {
            border-color: var(--color-primary) !important;
        }
    }

    @media (--screen-mini-small) {
        margin-block-start: 3rem;
    }
}
