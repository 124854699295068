.content {
    display: flow-root;

    &.-align-center {
        margin-inline: auto;
    }

    &.-small {
        max-width: 110rem;
    }
}
