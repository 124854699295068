@use 'sass:math';

// stylelint-disable
@mixin size($property, $minSize, $maxSize, $minViewportWidth: 320, $maxViewportWidth: 1280, $viewUnit: vw) {
    #{$property}: calc(#{math.div($minSize, 10)}rem + (#{$maxSize} - #{$minSize}) * (100#{$viewUnit} - #{math.div($minViewportWidth, 10)}rem) / (#{$maxViewportWidth} - #{$minViewportWidth}));

    @media (min-width: #{math.div($maxViewportWidth, 16)}rem) {
        #{$property}: #{math.div($maxSize, 10)}rem;
    }
    @media (max-width: #{math.div($minViewportWidth, 16)}rem) {
        #{$property}: #{math.div($minSize, 10)}rem;
    }
}
