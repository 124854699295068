@use 'input';

select:not([class]), .select {
    @extend %input;

    padding-inline-end: 2.8rem;

    background-image: url('../images/angle-down.svg');
    background-repeat: no-repeat;
    background-position: right 1em center;
    background-size: 1.2rem .8rem;

    &:not(:disabled) {
        cursor: pointer;
    }
}
