@use '../elements/list';

.checklist {
    @extend .list;

    margin-block-start: 1em;
    padding-inline-start: .7em;

    > li {
        padding-inline-start: .5em;

        &::marker {
            content: var(--checklist-marker-content, '\2713');
            color: var(--color-accent);
        }
    }
}
