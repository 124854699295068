.discount-balloon {
    position: absolute;
    z-index: 1;
    inset-block-start: 4.7rem;
    inset-inline-end: 1.4rem;
    transform: translateY(-50%) rotate(-10deg);

    display: inline-flex;
    align-items: center;
    justify-content: center;

    width: 8rem;
    height: 8rem;

    font-size: 2.5rem;
    font-weight: var(--font-primary-weight-bold);
    line-height: 1;
    color: var(--color-white);

    background: var(--color-secondary);
    border-radius: 50%;
}
