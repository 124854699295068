.language-toggle {
    position: relative;

    display: flex;

    margin: 0;
    padding-inline-start: 0;

    font-weight: var(--font-primary-weight-bold);
    list-style-type: none;

    &__item {
        @media (--show-desktop-menu) {
            display: none;
            order: 2;

            &.-active, .-open & {
                display: inline-block;
            }

            &.-active {
                order: 1;
            }
        }
    }

    &__link {
        display: block;
        text-decoration: none;
        text-transform: uppercase;
        transition: color .2s ease-in-out;

        @media (--show-desktop-menu) {
            .-active &::after {
                content: '';

                position: absolute;
                inset-block-start: .9rem;
                inset-inline-end: 0;

                display: block;

                width: 1.5rem;
                height: 1.2rem;

                background-color: currentcolor;

                mask-image: url('../images/angle-down.svg');
                mask-position: right;
                mask-size: 1.3rem;

                .-open & {
                    mask-image: url('../images/angle-up.svg');
                }
            }
        }

        &:--focus {
            color: var(--color-secondary);
        }

        @media (--header-medium) {
            .-overlay & {
                color: var(--color-white);
            }
        }
    }

    @media (--show-desktop-menu) {
        flex-direction: column;
        width: 4.4rem;
    }

    @media (--show-mobile-menu) {
        gap: 1em;
    }

    @media (--header-medium) {
        display: none;

        .-overlay & {
            inset-block-start: 3.2rem;
            inset-inline-start: .5rem;
            display: flex;
            background-color: transparent;
        }
    }
}
