%form__options {
    display: flex;
    flex-direction: column;
    gap: .3em 1.2em;
    margin-block-start: .6em; // align with other fields

    > * {
        position: relative;
        display: flex;
        align-items: flex-start;
    }
}

.form__options {
    @extend %form__options;
}

.form__options--inline {
    @extend %form__options;

    flex-direction: row;
    flex-wrap: wrap;
}
