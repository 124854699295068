%list {
    > li {
        margin: 0 0 .75em;
        padding: 0;

        &:last-child {
            margin-block-end: 0;
        }

        > ul, > ol {
            margin-block: .75em 0;
        }
    }
}

ul:not([class]), ol:not([class]), .list {
    @extend %list;

    margin-block-end: 1.7em;
    padding-inline-start: 1.5em;

    li::marker {
        color: var(--color-accent);
    }
}

ul:not([class]), ul.list {
    list-style-type: disc;
}

ol:not([class]), ol.list {
    list-style: decimal;

    li::marker {
        font-size: 120%;
    }
}
