@custom-media --footer-medium           (width < 810px);
@custom-media --footer-small            (width < 600px);

.l-footer {
    padding-block-start: 7rem;
    color: var(--footer-color, var(--color-primary-contrast-text));
    background-color: var(--footer-background-color, var(--color-primary));

    a {
        color: inherit;

        &:--focus {
            color: inherit;
            text-decoration: none;
        }
    }

    &__logo {
        display: block;
        max-width: 20rem;

        @media (--footer-medium) {
            align-self: center;
        }

        @media (--screen-mini) {
            max-width: 14rem;
        }
    }

    @media (--footer-medium) {
        padding-block: 5rem 1rem;
    }

    @media (--screen-mini-small) {
        padding-block: 3rem .7rem;
    }
}

.l-footer__top {
    display: flex;
    justify-content: space-around;

    max-width: 105rem;
    margin-block-end: 5rem;
    margin-inline: auto;

    font-weight: var(--l-footer-top-font-weight, inherit);

    @media (--footer-medium) {
        flex-direction: column;
        align-items: center;
        margin-block-end: 4rem;

        > nav {
            align-self: center;
        }
    }
}

.l-footer__bottom {
    display: flex;
    gap: 1rem 2rem;
    justify-content: center;

    margin-block-end: 3rem;

    font-size: 1.6rem;

    @media (--footer-small) {
        flex-direction: column;
        align-items: center;
    }
}
