@use '../mixins/responsive';

.product {
    display: flex;
    gap: 5vw;
    justify-content: space-between;

    &-images, &-content {
        flex-basis: 50%;
    }

    &-content {
        @media (--screen-medium) {
            margin-block-start: 4rem;
        }
    }

    &__price {
        @include responsive.size(font-size, 19, 25);

        display: inline-block;
        font-weight: var(--font-primary-weight-bold);
        color: var(--color-primary);
    }

    &__discount {
        color: var(--color-red);
        text-decoration: line-through;
        opacity: .7;
    }

    &__vta {
        font-size: 55%;
        font-weight: var(--font-primary-weight-regular);
        color: var(--color-gray-dark);
    }

    &__description {
        margin: 2em 0 3em;
    }

    &--availability {
        display: inline-block;

        margin-block: 1rem .5rem;
        padding: .2em .5em .1em;

        font-family: var(--font-secondary);
        font-weight: var(--font-secondary-weight-medium);

        &.-red {
            color: var(--color-red);
            border: .1rem solid var(--color-red);
        }

        &.-yellow {
            color: var(--color-yellow);
            border: .1rem solid var(--color-yellow);
        }

        &.-blue {
            color: var(--color-blue-light);
            border: .1rem solid var(--color-blue-light);
        }

        &.-green {
            color: var(--color-green);
            border: .1rem solid var(--color-green);
        }
    }

    @media (--screen-medium) {
        flex-direction: column;
    }
}

.contact-form {
    scroll-margin-top: 2em;
    max-width: 55rem;
}

.contact-success {
    font-family: var(--font-secondary);
    font-size: 3rem;
    line-height: 1.4;
    color: var(--color-primary);

    @media (--screen-small) {
        font-size: 2.5rem;
    }
}

.content-image {
    &-wrapper {
        width: 100%;
        max-width: 111.5rem;
        margin: 0 auto;

        img {
            height: auto;
            max-height: 61.5rem;
            margin-block-end: 4rem;
            object-fit: cover;
        }
    }
}

.content-title {
    text-align: center;
}
