%label {
    cursor: pointer;

    display: inline-block;

    margin: 0 0 .5em;

    font-size: inherit;
    line-height: 1.25;
    vertical-align: top; // Needed to keep label on same height in combination with checkbox between 1 line and multiline label
}

label:not([class]), .label {
    @extend %label;

    &[for*='IgnoreThis'] { // Hide honeypot fields
        display: none;
    }

    > small {
        margin-inline-start: .5em;
        opacity: .9;
    }
}

.label--option {
    @extend %label;

    margin-inline-start: .6em;
    font-weight: var(--font-primary-weight-regular);
    line-height: 1.5;
}

.label__info {
    font-weight: normal;
}
