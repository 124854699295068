.menu-button {
    display: none;

    @media (--show-mobile-menu) {
        z-index: var(--z-index-menu-button);
        display: flex;
        align-items: center;

        .hamburger {
            margin-inline-start: 1rem;
        }
    }
}
