.form__button-input {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem var(--form-column-gap);
    align-items: center;

    > button {
        margin: 0;
    }

    > input {
        flex: 1;
        min-width: 16rem;
        max-width: 20rem;
        text-align: center;

        &[disabled] {
            opacity: 1;
            border: 0;
        }
    }
}
